import { render, staticRenderFns } from "./bian.vue?vue&type=template&id=bcb6cac6&scoped=true&"
import script from "./bian.vue?vue&type=script&lang=js&"
export * from "./bian.vue?vue&type=script&lang=js&"
import style0 from "./bian.vue?vue&type=style&index=0&id=bcb6cac6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcb6cac6",
  null
  
)

export default component.exports