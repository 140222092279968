<template>
  <div>
    <div style="min-height: 700px; padding: 20px 0; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>信用评价</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <div class="excessive">
        <img class="img" :src="excessive" alt="" />
        <div class="excessive-info">
          <div class="excessive1">
            通过个人或者班组信<br />用评价上传认证资料
          </div>
          <div class="excessive2">等待评价</div>
          <div class="excessive3">评价完成</div>
        </div>
      </div>
      <div class="tabBack" v-show="tabHover < 3">
        <div
          class="tabOne"
          @click="tabCheck(1, '个人')"
          :class="tabHover == 1 ? 'tab-active' : ''"
        >
          个人信用评价
        </div>
        <div
          class="tabTwo"
          @click="tabCheck(2, '班组')"
          :class="tabHover == 2 ? 'tab-active' : ''"
        >
          班组信用评价
        </div>
      </div>
      <div class="creditrating" v-if="tabHover == 1">
        <div class="creditrating-box">
          <div class="head-title">
            <div class="credit-again">
              <div
                class="personTeam"
              >个人信用评价资料已完善{{ percentage }}%</div>
              <el-button class="personBtn" type="primary" @click="toEvaluation(obj,1)"
                >  {{ isSubmit ? '继续完善' : '已完善' }}
                </el-button>
            </div>
            <div class="title">
              <span class="mes-title" style="cursor:pointer;" @click="getBCSGradInfo(userAuth.identity_code,'1')">信用存证 >></span>
            </div>
          </div>
          <!-- 左边 -->
          <div class="creditrating-min">
            <div class="left">
              <h1 style="margin-top: 67px; margin-left: 42px">
                {{ obj.name }}
              </h1>
              <div>
                <span
                  style="margin: 0 20px 0 45px"
                  v-html="'信\u00a0\u00a0用\u00a0\u00a0分: '"
                ></span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade >= 80"
                  :style="{
                    color: gradeColor[0],
                    borderColor: gradeColor[0],
                  }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade < 80 && item.grade >= 60"
                  :style="{
                    color: gradeColor[1],
                    borderColor: gradeColor[1],
                  }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade < 60 && item.grade >= 40"
                  :style="{
                    color: gradeColor[2],
                    borderColor: gradeColor[2],
                  }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade < 40"
                  :style="{
                    color: gradeColor[3],
                    borderColor: gradeColor[3],
                  }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span>分</span>
                <div style="margin-left: 47px;font-size: 14px; color: #999999;">此评分为根据您上传的相关信息进行的基本评价,如需更详细的评价请上传相关资料。</div>
              </div>

              <div>
                <p style="margin-top: 20px; margin-left: 47px">
                  <span style="margin: 0 20px 0 0" v-html="'出生年月:'"></span>
                  <span>{{ obj.birthday }}</span>
                </p>

                <p style="margin-top: 20px; margin-left: 47px">
                  <span
                    style="margin: 0 20px 0 0"
                    v-html="'年\u2003\u2003龄:'"
                  ></span>
                  <span>{{ obj.age }}岁</span>
                </p>

                <p style="margin-top: 20px; margin-left: 47px">
                  <span
                    style="margin: 0 20px 0 0"
                    v-html="'学\u2003\u2003历:'"
                  ></span>
                  <span>{{ getPostEdu(obj.edu) }}</span>
                </p>
                <p style="margin-top: 20px; margin-left: 47px">
                  <span
                    style="margin: 0 20px 0 0"
                    v-html="'民\u2003\u2003族:'"
                  ></span>
                  <span>{{ obj.nation }}</span>
                </p>

                <p style="margin-top: 20px; margin-left: 47px">
                  <span style="margin: 0 20px 0 0" v-html="'联系方式:'"></span>
                  <span>{{ obj.phone }}</span>
                </p>
              </div>
            </div>

            <!-- 右边 -->
            <div class="right">
              <h2 style="text-align: center">基本评价</h2>
              <biantu ref="biantu"></biantu>

              <h2 style="text-align: center; margin-top: 45px">信用评价</h2>
              <bian ref="bian"></bian>
            </div>
          </div>
        </div>
      </div>
      <div class="creditrating" v-if="tabHover == 2">
        <div class="creditrating-box">
          <div class="head-title">
            <div class="credit-again">
              <div class="personTeam">班组信用评价资料已完善{{ percentage }}%</div>
              <el-button class="personBtn" type="primary" @click="toEvaluation(userTeam,3)"
                >{{ isSubmit ? '继续完善' : '已完善' }}</el-button>
            </div>
          <div class="title">
              <span class="mes-title" style="cursor:pointer;" @click="getBCSGradInfo(`t${userTeam.idCard}`, '3')">信用存证 >></span>
          </div>
          </div>

          <!-- 左边 -->
          <div class="creditrating-min">
            <div class="left">
              <h1 style="margin-top: 67px; margin-left: 42px">
                {{ obj.name }}
              </h1>
              <div style="margin-left: 42px">
                <el-tag
                  v-for="(tag, ind) in userTeam.teamType"
                  :key="ind"
                  :type="tag"
                >
                  {{ showTeamType(tag) }}
                </el-tag>
              </div>
              <div>
                <span
                  style="margin: 0 20px 0 45px"
                  v-html="'信\u00a0\u00a0用\u00a0\u00a0分: '"
                ></span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade >= 80"
                  :style="{
                    color: gradeColor[0],
                    borderColor: gradeColor[0],
                  }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade < 80 && item.grade >= 60"
                  :style="{
                    color: gradeColor[1],
                    borderColor: gradeColor[1],
                  }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade < 60 && item.grade >= 40"
                  :style="{
                    color: gradeColor[2],
                    borderColor: gradeColor[2],
                  }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  style="font-size: 28px"
                  class="score_text"
                  v-if="item.grade < 40"
                  :style="{
                    color: gradeColor[3],
                    borderColor: gradeColor[3],
                  }"
                >
                  {{ Number(item.grade) == 0 ? Number(item.grade) : Number(item.grade).toFixed(1) }}
                </span>
                <span>分</span>
                <div style="margin-left: 47px;font-size: 14px; color: #999999;">此评分为根据您上传的相关信息进行的基本评价,如需更详细的评价请上传相关资料。</div>
              </div>

              <div>
                <p style="margin-top: 20px; margin-left: 47px">
                  {{ obj.sex == "2" ? "女" : "男" }}
                  <span>|</span>
                  {{ obj.age }}岁
                  <span>|</span>
                  {{ obj.nation ? obj.nation : "汉" }}
                  <span>|</span>
                  {{ getPostEdu(obj.edu) }}
                </p>
                <p style="margin-top: 20px; margin-left: 47px">
                  职位:
                  <span
                    class="spanInfoSize"
                    v-for="(data, ind) in userTeam.postType"
                    :key="ind"
                    >
                    {{ ind == userTeam.postType.length-1 ? showPostType(postTypeList, data) : (showPostType(postTypeList, data) + ',') }}</span>
                </p>
              </div>
              <div
                class="left-three"
                style="margin-top: 20px; margin-left: 47px"
              >
                <h3>核心成员</h3>
                <div class="three-content">
                  <div class="left" style="display: flex;">
                    <p style="width:40%">{{ userTeam.name }}</p>
                    <p class="pInfoSize" style="margin-left:20px">
                      <span
                      class="spanInfoSize"
                      v-for="(data, ind) in userTeam.postType"
                      :key="ind"
                      >{{ ind == userTeam.postType.length-1 ? showPostType(postTypeList, data) : (showPostType(postTypeList, data) + ',') }}</span
                    >
                    </p>
                  </div>
                  <div class="right" style="display: flex;">
                    <p style="width:40%">
                      <span
                        class="spanInfoSize"
                        v-for="(info, idx) in userTeam.teamType"
                        :key="idx"
                        >{{ idx == userTeam.teamType.length-1 ? showTeamType(info) : (showTeamType(info) + ',') }}</span
                      >
                    </p>
                    <p style="margin-left:20px">{{ userTeam.phone }}</p>
                  </div>
                </div>
                <div
                  class="three-content"
                  v-for="(data, idx) in userTeam.users"
                  :key="idx"
                >
                  <div class="left" style="display: flex;">
                    <p style="width:40%">{{ data.name }}</p>
                    <p class="pInfoSize" style="margin-left:20px">
                      <span
                        class="spanInfoSize"
                        v-for="(info, ind) in data.postType"
                        :key="ind"
                        >
                        {{ ind == data.postType.length-1 ? showPostType(postTypeList, info) : (showPostType(postTypeList, info) + ',') }}
                        </span>
                    </p>
                  </div>
                  <div class="right" style="display: flex;">
                    <p style="width:40%">
                      <span
                        class="spanInfoSize"
                        v-for="(info, idx) in userTeam.teamType"
                        :key="idx"
                        >{{ idx == userTeam.teamType.length-1 ? showTeamType(info) : (showTeamType(info) + ',')}}</span
                      >
                    </p>
                    <p style="margin-left:20px">{{ data.phone }}</p>
                  </div>
                </div>
              </div>
              <div
                class="left-four"
                style="margin-top: 20px; margin-left: 47px"
              >
                <h3>项目经历</h3>
                <div v-for="(earl, idxx) in userTeam.projects" :key="idxx">
                  <p v-if="earl.project">{{ earl.project }}</p>
                  <div>
                    <div class="left">
                      <p v-if="earl.teamType">
                        工种:
                        <span
                          class="spanInfoSize"
                          v-for="(info, idx) in earl.teamType"
                          :key="idx"
                          >{{ idx == userTeam.teamType.length-1 ? showTeamType(info) : (showTeamType(info) + ',') }}</span>
                      </p>
                    </div>
                    <div class="four-content">
                      <p v-if="earl.company" style="margin-right: 75px">{{ earl.company }}</p>
                      <p v-if="earl.startTime || earl.startTime">
                        {{ moment(earl.startTime).format("YYYY-MM") }}-
                        {{ moment(earl.endTime).format("YYYY-MM") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 右边 -->
            <div class="right">
              <!-- <h2 style="text-align: center">基本评价</h2>
              <biantu ref="biantu"></biantu> -->

              <h2 style="text-align: center; margin-top: 45px">信用评价</h2>
              <bian ref="bian"></bian>
            </div>
          </div>
        </div>
      </div>
      <el-empty
        v-show="tabHover == 5"
        description="暂无信用评价信息"
      ></el-empty>
    </div>
    <el-dialog
      width="900px"
      :visible.sync="outerVisible"
      custom-class="previewDialog"
    >
      <div class="create-info">
        <div class="user-info">
          <div class="logo-title">
            <img class="logo-img" src="@/assets/home/logo.png" alt="" />
            <div class="logo-text">甄工匠</div>
          </div>
          <div class="title-name">信用评价存证信息</div>
          <div class="left">
            <p class="user-name">{{ obj.name }}</p>
            <div class="info-data">
              {{ obj.sex == "2" ? "女" : "男" }}
              <span>|</span>
              {{ obj.age }}岁
              <span>|</span>
              {{ obj.idCard }}
            </div>
            <p v-if="obj.postName">
              职位: <span>{{ obj.postName }}</span>
            </p>
          </div>
          <div class="right">
            <div class="grade-title">信用评价总得分</div>
            <p
              class="grade"
              v-if="item.grade >= 80"
              :style="{
                color: gradeColor[0],
                borderColor: gradeColor[0],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 80 && item.grade >= 60"
              :style="{
                color: gradeColor[1],
                borderColor: gradeColor[1],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 60 && item.grade >= 40"
              :style="{
                color: gradeColor[2],
                borderColor: gradeColor[2],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 40"
              :style="{
                color: gradeColor[3],
                borderColor: gradeColor[3],
              }"
            >
              {{Number(item.grade) == 0 ? Number(item.grade) : Number(item.grade).toFixed(1) }}
            </p>
          </div>
        </div>
        <div class="detail-info">
          <div class="title-name">上链详情</div>
          <div v-for="(item, index) in data" :key="index" class="up-info">
            <div class="left">{{ item.name }}</div>
            <div class="right">{{ item.info }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import biantu from "./biantu/biantu.vue"; // 雷达图
import bian from "./biantu/bian.vue";
import moment from "moment";
import { jobPostType } from "@/api/position/position";
import {
  jobEvaluationDetail,
  getBCSGrad,
  getLoginUser,
  jobEvaluationAdd,
  jobEvaluationPage,
  getLoginUserInfo,
} from "@/api/evaluation/evaluation";
import { jobScoringList} from "@/api/evaluation/evaluation";
import { detailTeam } from "@/api/team/team";
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
export default {
  components: {
    biantu,
    bian,
  },
  data() {
    return {
      isSubmit: false,
      percentage: 0,
      isExitHover: false,
      excessive: require("@/assets/icon/excessiveInfo.png"),
      tabHover: 1,
      hoverCopy: 1,
      form: {
        createUser: "", //反馈用户id
        content: "", //内容
        img: "", //图片,逗号分隔
      },
      fileList: [],
      urls: [],
      gradeColor: ["#67CF61", "#3B94F7", "#e6a23c", "#ff0000"],
      data: [
        {
          name: "区块高度",
          info: "",
        },
        {
          name: "上链时间",
          info: "",
        },
        {
          name: "上链ID",
          info: "",
        },
        {
          name: "上链状态",
          info: "",
        },
      ],
      outerVisible: false,
      showVisible: false,
      listInfo: [],
      obj: {},
      item: {},
      item2: {
        mapType: {},
        mapTypeBase: {},
      },
      dict: [],
      orgType: [],
      jobEdu: [],
      cityList: [],
      jobExp: [],
      option: {
        color: ["#fc9a40"],
        radar: {
          indicator: [],
        },
        series: [
          {
            name: "往年评分 vs 当前评分",
            type: "radar",
            data: [
              {
                value: [],
                name: "往年评分",
                // label: {
                //   normal: {
                //     show: true,
                //     extStyle: {
                //       color: "blue",
                //     },
                //   },
                // },
              },
            ],
          },
        ],
      },
      dataList: {
        color: ["#fc9a40"],
        radar: {
          indicator: [],
        },
        series: [
          {
            name: "往年评分 vs 当前评分",
            type: "radar",
            data: [
              {
                value: [],
                name: "往年评分",
                // label: {
                //   normal: {
                //     show: true,
                //     extStyle: {
                //       color: "blue",
                //     },
                //   },
                // },
              },
            ],
          },
        ],
      },
      copyInfo: {
        color: ["#fc9a40"],
        radar: {
          indicator: [],
        },
        series: [
          {
            name: "往年评分 vs 当前评分",
            type: "radar",
            data: [
              {
                value: [],
                name: "往年评分",
                // label: {
                //   normal: {
                //     show: true,
                //     extStyle: {
                //       color: "blue",
                //     },
                //   },
                // },
              }
            ],
          },
        ],
      },
      params: {
        pageNo: 1,
        pageSize: 5,
      },
      totalList: 1,
      totalRows: 0,
      userLoginId: "",
      teamUserId: "",
      EvaluationList: [], //历史记录
      userTeam: {},
      userAuth: {},
      team_type: [],
      postTypeList: [], //职位类型列表
    };
  },
  computed: {},
  created() {
    if(this.$route.query.idCard){
      this.loadData(this.$route.query.idCard,this.$route.query.val)
    }
  },
  mounted() {},
  methods: {
    moment,
    toEvaluation(e,val) {
      let jobUserId = localStorage.getItem("userId");
      this.$router.push({
          path: "/creditEvaluation",
          query: {
          idCard: e.idCard ? e.idCard : '',
          value: val,
          userId: val == 1 ? jobUserId : e.id
        }
      })
    },
    goBack() {
      this.tabHover = this.hoverCopy ? this.hoverCopy : 1;
      getJobUserAuth().then((res) => {
        this.userAuth = res.data;
        if (this.tabHover == 1) {
          this.loadData(this.userAuth.identity_code, 1);
        } else {
          this.loadData(this.userTeam.idCard, 2);
        }
      });
      this.$forceUpdate();
    },
    chenPagination(cur) {
      this.params.pageNo = cur;
      this.toHistory();
    },
    tabCheck(val, str) {
      if (str == "班组") {
        let jobUserId = localStorage.getItem("userId");
        detailTeam({ jobUserId }).then((res) => {
          this.userTeam = res.data;
          if (res.data == null) {
            this.$message.error("暂无班组评价信息");
            this.tabHover = 1;
            this.hoverCopy = 1;
          } else if (res.data.auditStatus == 2) {
            this.tabHover = val;
            this.hoverCopy = val;
            this.teamUserId = res.data.id;
            this.userTeam.postType = this.userTeam.postType.split(",");
            this.userTeam.teamType = this.userTeam.teamType.split(",");
            this.userTeam.users.map((item) => {
              item.postType = item.postType.split(",");
            });
            this.userTeam.projects.map((item) => {
              item.teamType = item.teamType.split(",");
            });
            this.loadData(this.userTeam.idCard, 2);
          } else {
            this.$message.error("班组评价信息审核中");
            this.tabHover = 1;
            this.hoverCopy = 1;
          }
        });
      } else if (str == "个人") {
        getJobUserAuth().then((res) => {
          if (res.data && res.data.identity_code && res.data.audit_status == '2') {
            this.userAuth = res.data;
            this.tabHover = val;
            this.hoverCopy = val;
            this.loadData(this.userAuth.identity_code, 1);
          }else if(res.data == null){
            this.$message.error("暂无个人评价信息");
          }else{
            this.$message.error("个人评价信息审核中");

          }
        });
      }
    },
    teamInfoData(val) {
      let jobUserId = localStorage.getItem("userId");
      detailTeam({ jobUserId }).then((res) => {
        this.userTeam = res.data;
        this.tabHover = val;
        this.hoverCopy = val;
        this.teamUserId = res.data.id;
        this.userTeam.postType = this.userTeam.postType.split(",");
        this.userTeam.teamType = this.userTeam.teamType.split(",");
        this.userTeam.users.map((item) => {
          item.postType = item.postType.split(",");
        });
        this.userTeam.projects.map((item) => {
          item.teamType = item.teamType.split(",");
        });
      });
    },
    userAuthData(){
      getJobUserAuth().then((res) => {
          this.userAuth = res.data;
        });
    },
    toRecord() {
      this.showVisible = !this.showVisible;
      console.log(this.showVisible);
      if (this.hoverCopy == 1) {
        this.toHistory();
      } else if (this.hoverCopy == 2) {
        this.toHistory();
      }
    },
    toHistory() {
      if (this.hoverCopy == 1) {
        this.params.userType = 1;
        this.params.userId = this.userLoginId ? this.userLoginId : "";
      } else {
        this.params.userType = 3;
        this.params.userId = this.userLoginId ? this.userLoginId : "";
      }
      if (this.userLoginId) {
        jobEvaluationPage(this.params).then((res) => {
          this.totalRows = res.data.totalPage;
          this.EvaluationList = res.data.rows;
          this.EvaluationList.map((item) => {
            item.filesList = item.files.split(",");
            return item;
          });
        });
      } else {
        this.EvaluationList = [];
      }
    },
    hideVisible() {
      this.showVisible = !this.showVisible;
    },
    creditAgain(val, str) {
      this.tabHover = val;
    },
    handleAvatarSuccess(res, file, fileList) {
      console.log(res, "res");
      console.log(file, "file");
      console.log(fileList, "fileList");
      if (res) {
        this.urls.push(file);
      }
    },
    handleRemove(file) {
      this.urls = this.urls.filter((i) => i.url != file.url);
    },
    // 显示班组类型
    showTeamType(val) {
      if (val) {
        return this.team_type.filter((i) => i.code == val)[0].name;
      } else {
        return "";
      }
    },
    // 显示职位
    showPostType(list, id) {
      var title = "";
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title;
          break;
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.showPostType(list[i].children, id);
          if (title) return title;
        }
      }
      return title;
    },
    // 职位类型
    getJobPostType() {
      jobPostType({ code: "post_type" }).then((res) => {
        this.postTypeList = this.hasChildren(res.data[0].children);
      });
    },
    hasChildren(list) {
      list.forEach((item) => {
        if (item.children.length > 0) {
          this.hasChildren(item.children);
        } else {
          item.children = null;
        }
      });
      return list;
    },
    loadData(idCard, val) {
      if (val == 1) {
        this.tabHover = val;
        this.hoverCopy = val;
        this.userAuthData();
        getLoginUser().then((res) => {
        this.userLoginId = res.data.id;
        this.jobScoring(idCard,val,this.userLoginId);
      });
      } else if (val == 2) {
        this.tabHover = val;
        this.hoverCopy = val;
        this.teamInfoData(val);
        detailTeam({ jobUserId: localStorage.getItem("userId")}).then((res) => {
        this.jobScoring(idCard,val,res.data.id);
      });
      } else {
        this.tabHover = 5;
        this.hoverCopy = 5;
      }
      this.dict = JSON.parse(localStorage.getItem("dict"));
      this.orgType = this.dict.filter((i) => i.code == "job_org_type")[0].children;
      this.getJobPostType();
      this.jobEdu = this.dict.filter((i) => i.code == "job_edu")[0].children;
      this.jobExp = this.dict.filter((i) => i.code == "job_exp")[0].children;
      this.team_type = this.dict.find((e) => e.code === "team_type").children;

      jobEvaluationDetail({ idCard: idCard, type: val == 1 ? '1' : '3' }).then((res) => {
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
          this.obj = res.data.rows[0];
          this.obj.birthday = moment(this.obj.birthday).format("YYYY-MM");
          this.item = res.data.rows[0];
          this.item2 = res.data.rows[1] ? res.data.rows[1] : this.item2;
          this.mapTypeInfo();
          if (JSON.stringify(this.item.mapTypeBase) === "{}") {
          } else {
            this.mapTypeBaseInfo();
          }
          var that = this;
          setTimeout(() => {
            that.$refs.bian.budil(this.option);
            if (JSON.stringify(this.item.mapTypeBase) !== "{}") {
              that.$refs.biantu.budil(this.dataList);
            }else{
              that.$refs.biantu.budil(this.copyInfo);
            }
          }, 500);
        } else {
          this.item = "";
          this.obj = {};
          this.item2 = this.item2;
          this.tabHover = 5;
          this.hoverCopy = 5;
        }
      }).catch(error=>{
          this.item = "";
          this.obj = {};
          this.item2 = this.item2;
          this.tabHover = 5;
          this.hoverCopy = 5;
      })
    },
    async jobScoring(idCard,val,id){
      let params = {
        idCard: idCard,
        userType: val == 2 ? 3 : 1,
        type: val == 2 ? 3 : 1,
        targetId: id
      }
      let res = await jobScoringList(params)
      this.percentage = res.data.ratio
      this.isSubmit = res.data.isSubmit
    },
    // 信用存证
    getBCSGradInfo(idCard , userType){
      this.outerVisible = !this.outerVisible;
      getBCSGrad({ idCard, userType})
          .then((res) => {
            if (res.data) {
              let info = res.data;
              info.Value = JSON.parse(info.Value);
              info.Timestamp = this.timestampToTime(
                info.Timestamp.slice(0, 10)
              );
              info.BlockNum = info.BlockNum + 1;
              this.data[0].info = info.BlockNum;
              this.data[1].info = info.Timestamp;
              this.data[2].info = info.TxHash;
              this.data[3].info = info.IsDeleted ? "error" : "Success";
            } else {
              this.data[0].info = "";
              this.data[1].info = "";
              this.data[2].info = "";
              this.data[3].info = "";
              this.$message("用户暂无存证信息");
            }
          })
          .catch((error) => {
            this.data[0].info = "";
            this.data[1].info = "";
            this.data[2].info = "";
            this.data[3].info = "";
            this.$message.error("用户暂无存证信息");
          });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D;
    },
    // 信用评价
    // mapTypeInfo() {
    //   this.option.radar.indicator = []
    //   console.log(this.item2);
    //   let keys1 = Object.keys(this.item.mapType);
    //   let keys2 = Object.keys(this.item2.mapType);
    //   let values1 = Object.values(this.item.mapType);
    //   let values2 = Object.values(this.item2.mapType);
    //   let keys = []
    //   if(keys1.length > keys2.length){
    //     keys = keys1
    //   }else{
    //     keys = keys2
    //   }
    //   let values = []
    //   if(values1.length > values2.length){
    //     values = values1
    //     values.map((item,index)=>{
    //       console.log(item)
    //     })
    //   }else{
    //     values = values2
    //     values.map((item,index)=>{
    //       console.log(item[0])
    //     })
    //   }
    //   values.map((item,index)=>{
    //     let obj = {
    //       name: keys[index],
    //       max:item[0]
    //     }
    //      this.option.radar.indicator.push(obj)
    //   })
    //   this.option.radar.indicator.map((item, index) => {
    //     this.option.radar.indicator[index].name = keys[index] ? keys[index] : '';
    //     this.option.radar.indicator[index].max = values[index] ? values[index][0] : 0;
    //     this.option.series[0].data[1].value[index] = values1[index] ? values1[index][1] : 0;
    //     this.option.series[0].data[0].value[index] = values2[index] ? values2[index][1] : 0;
    //   });
    //   console.log(this.option.series[0].data, "信用评价");
    // },
     mapTypeInfo() {
      this.option.radar.indicator = []
      this.option.series[0].data[0].value = []
      let keys1 = Object.keys(this.item.mapType);
      let values1 = Object.values(this.item.mapType);
      values1.map((item,index)=>{
        let obj = {
          name: keys1[index],
          max:item[0]
        }
         this.option.radar.indicator.push(obj)
         this.option.series[0].data[0].value[index] = item[1]
      })
      console.log(this.option, "信用评价");
    },
    // 基本评价
    // mapTypeBaseInfo() {
    //   let keys1 = Object.keys(this.item.mapTypeBase);
    //   let keys2 = Object.keys(this.item2.mapTypeBase);
    //   let values1 = Object.values(this.item.mapTypeBase);
    //   let values2 = Object.values(this.item2.mapTypeBase)
    //   // console.log(keys,values,values2,222222222222);
    //   let keys = []
    //   if(keys1.length > keys2.length){
    //     keys = keys1
    //   }else{
    //     keys = keys2
    //   }
    //   let values = []
    //   if(values1.length > values2.length){
    //     values = values1
    //   }else{
    //     values = values2
    //   }
    //   this.dataList.radar.indicator.map((item, index) => {
    //     this.dataList.radar.indicator[index].name = keys[index] ? keys[index] : '';
    //     this.dataList.radar.indicator[index].max = values[index] ? values[index][0] : 0;
    //     this.dataList.series[0].data[1].value[index] = values1[index] ? values1[index][1] : 0;
    //     this.dataList.series[0].data[0].value[index] = values2[index] ? values2[index][1] : 0;
    //   });
    //   console.log(this.dataList.series[0].data, "基本评价");
    // },
     mapTypeBaseInfo() {
      this.dataList.radar.indicator = []
      this.dataList.series[0].data[0].value = []
      let keys1 = Object.keys(this.item.mapTypeBase);
      let values1 = Object.values(this.item.mapTypeBase);
      values1.map((item,index)=>{
        let obj = {
          name: keys1[index],
          max:item[0]
        }
         this.dataList.radar.indicator.push(obj)
         this.dataList.series[0].data[0].value[index] = item[1]
      })
      console.log(this.dataList, "基本评价");
    },
    getPostEdu(id) {
      if (id) {
        return this.jobEdu.filter((i) => i.code == id)[0].name;
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.personTeam {
  // border: 3px solid #0099ff;
  border-radius: 4px;
  // width: 151px;
  // height: 42px;
  text-align: center;
  line-height: 48px;
  margin-left: 20px;
  // color: #0099ff;
  // cursor: pointer;
}
// .personTeam:hover {
//   background-color: #0099ff;
//   color: #fff !important;
// }

.pInfoSize {
  width: 230px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;

}
.excessive {
  width: 85%;
  height: 180px;
  margin: 0 auto;
  text-align: center;
  .img {
    width: 100%;
  }
  .excessive-info {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    color: #71aaf0;
    .excessive1 {
      margin-left: -2%;
      margin-top: -2%;
    }
    .excessive2 {
      flex: 1;
      margin-left: -2%;
      margin-top: -2%;
    }
    .excessive3 {
      margin-top: -2%;
      margin-right: 1%;
    }
  }
}
.mes-title {
  margin-right: 20px;
  font-size: 16px !important;
  color: #0d9ff9 !important;
}
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.tabBack {
  display: flex;
  justify-content: center;
  text-align: center;
  .tabOne {
    width: 20%;
    height: 48px;
    line-height: 48px;
  }
  .tabTwo {
    width: 20%;
    height: 48px;
    line-height: 48px;
  }
  .tab-active {
    // border-top: #0d9ff9 1px solid;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    // border-left: #0d9ff9 1px solid;
    // border-right: #0d9ff9 1px solid;
    background: rgba(179, 228, 255, 0.3);
  }
}

.head-title {
  height: 74px;
  text-align: right;
  // margin-right: 20px;
  .title {
    margin-top: 10px;
    color: #0d9ff9;
  }
  .credit-again {
    height: 48px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: rgba(179, 228, 255, 0.3);
    color: #1CA4FF;
    align-items: center;
    .personBtn{
      width: 100px;
      height: 32px;
      border-radius: 50px;
      margin-right: 20px;
      padding: 0;
    }
  }
}

.creditrating {
  width: 946px;
  // height: 1200px;
  // background: rgba(255, 255, 255, 1);
  background-color: #fafafa;
  margin: 0 20px;
  border-radius: 3px;
  // padding-top: 32px;
  .creditrating-box {
    // margin-left: 36px;
    border: 3px solid rgba(179, 228, 255, 0.3);;
    border-radius: 3px;
    padding-bottom: 30px;
    span {
      color: rgba(51, 51, 51, 1);
      font-size: 22px;
    }
  }

  .creditrating-min {
    display: flex;
    justify-content: space-around;
    .left {
      width: 500px;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      // line-height: 36px;
    }
    .right {
      width: 500px;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      line-height: 36px;
    }
  }
}
.creditrating3 {
  width: 946px;
  background-color: #fafafa;
  margin: 0 20px;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 20px;
  .creditrating-head {
    display: flex;
    justify-content: space-between;
    .title-span {
      color: #636363;
      font-weight: bold;
    }
  }
  .dialog-footer {
    text-align: right;
  }
}

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

::v-deep .el-dialog__header {
  display: none;
  padding: 0 !important;
}
::v-deep .el-dialog {
  background-color: rgba(254, 254, 254, 0);
  box-shadow: none;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
  background-image: url("~@/assets/course/up-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.previewDialog {
  .create-info {
    display: flex;
  }

  .user-info {
    width: 28%;
    align-items: center;
    padding: 30px 30px;

    .logo-title {
      text-align: center;
      .logo-img {
        width: 47px;
        height: 53px;
      }
      .logo-text {
        color: #0d9ff9;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .title-name {
      padding: 10px 20px;
      margin-top: 20px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    .user-name {
      font-size: 18px;
      font-weight: bold;
    }
    .left {
      .info-data {
        margin: 20px 0;
      }
    }
    .right {
      overflow: hidden;
      align-items: center;
      display: flex;
      .grade-title {
        font-size: 18px;
        margin-right: 20px;
      }
      .grade {
        color: #ff5a47;
        font-size: 28px;
        font-family: buer;
      }
    }
  }
  .detail-info {
    width: 55%;
    padding: 15px 0;
    margin-left: 40px;
    .title-name {
      padding: 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .up-info {
      display: flex;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 10px;
      margin: 7px 0;

      .left {
        width: 20%;
        font-size: 16px;
      }
      .right {
        flex: 1;
        width: 50%;
        font-size: 16px;
      }
    }
  }
}

.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .mask-box {
    background-color: #fff;
    width: 960px;
    height: 595px;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 20px;
    .head-title1 {
      display: flex;
      justify-content: space-between;
      .icon-span {
        font-size: 24px;
        margin: 0;
      }
    }
    .content-nav {
      .infoList {
        width: 96%;
        margin: 15px auto;
        border-radius: 6px;
        background-color: #f5f5f5;
        box-sizing: border-box;
        padding: 10px 20px;
        .content-info {
          text-indent: 60px;
        }
        .img-box {
          .img-info {
            width: 115px;
            height: 89px;
            margin: 10px;
          }
        }
      }
    }
  }
  .mask-box::-webkit-scrollbar {
    width: 0 !important;
  }
  .mask-box {
    -ms-overflow-style: none;
  }
  .mask-box {
    overflow: -moz-scrollbars-none;
  }
}
.four-content {
  display: flex;
}
.bottom-time {
  text-align: right;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.spanInfoSize {
  font-size: 16px !important;
}
.head-status {
  text-align: right;
}
</style>
