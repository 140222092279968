import request from "@/api/request"

// 职位搜索
export function jobPostSearch(data) {
  return request({
    method: 'get',
    // url: '/main/jobPost/search',
    url: '/job/oauth/jobPost/search',
    params:data
  })
}

// 职位类型
export function jobPostType(data) {
  return request({
    method: 'get',
    // url: '/main/jobPostType/tree',
    url: '/main/oauth/jobPostType/tree',
    params:data
  })
}

// 职位详情
export function jobPostInfo(data) {
  return request({
    method: 'get',
    // url: '/main/jobPost/info',
    url: '/job/oauth/jobPost/info',
    params:data
  })
}

// 职位阅读量，收藏
export function jobPostRedCollectAdd(data) {
  return request({
    method: 'post',
    // url: '/main/jobPostRedCollect/add',
    url: '/job/oauth/jobPostRedCollect/add',
    data:data
  })
}

// 简历投递
export function submitResume(data) {
  return request({
    method: 'post',
    url: '/job/jobApply/submitResume',
    data:data
  })
}

// 添加职位搜索关键字
export function jobSearchAdd(data) {
  return request({
    method: 'post',
    url: '/main/oauth/jobSearchAdd',
    data:data
  })
}

// 查询职位搜索关键字
export function jobSearchPage(data) {
  return request({
    method: 'get',
    url: '/main/oauth/jobSearchPage',
    params:data
  })
}

// 企业详情
export function jobOrgInfo(data) {
  return request({
    method: 'get',
    // url: 'job/jobOrgInfo/detailInfo',
    url: 'job/oauth/jobOrgInfo/detailInfo',
    params:data
  })
}

// 看过的职位
export function jobPostRedCollectList(data) {
  return request({
    method: 'get',
    url: 'job/oauth/jobPostRedCollect/list',
    params:data
  })
}
